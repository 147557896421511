import { HttpClient } from "../HttpClient";
/**
 * * Post Asset File
 */
export const PutAssetTrackingStatus = async (assetId,IsTracked) => {
    try {
        const { data } = await HttpClient.put(`api/pexassets/${assetId}/TrackingStatus`, {...IsTracked});
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
